exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-page-account-page-js": () => import("./../../../src/pages/account-page/AccountPage.js" /* webpackChunkName: "component---src-pages-account-page-account-page-js" */),
  "component---src-pages-dashboard-dashboard-js": () => import("./../../../src/pages/dashboard/Dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-dashboard-js" */),
  "component---src-pages-forgot-password-forgot-password-js": () => import("./../../../src/pages/forgot-password/ForgotPassword.js" /* webpackChunkName: "component---src-pages-forgot-password-forgot-password-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landing-page-landing-page-js": () => import("./../../../src/pages/landing-page/LandingPage.js" /* webpackChunkName: "component---src-pages-landing-page-landing-page-js" */),
  "component---src-pages-login-login-js": () => import("./../../../src/pages/login/Login.js" /* webpackChunkName: "component---src-pages-login-login-js" */),
  "component---src-pages-logout-logout-js": () => import("./../../../src/pages/logout/Logout.js" /* webpackChunkName: "component---src-pages-logout-logout-js" */),
  "component---src-pages-settings-settings-js": () => import("./../../../src/pages/settings/Settings.js" /* webpackChunkName: "component---src-pages-settings-settings-js" */),
  "component---src-pages-template-404-template-404-js": () => import("./../../../src/pages/template-404/Template404.js" /* webpackChunkName: "component---src-pages-template-404-template-404-js" */),
  "component---src-pages-track-invitations-track-invitations-js": () => import("./../../../src/pages/track-invitations/TrackInvitations.js" /* webpackChunkName: "component---src-pages-track-invitations-track-invitations-js" */),
  "component---src-pages-verify-email-verify-email-js": () => import("./../../../src/pages/verify-email/VerifyEmail.js" /* webpackChunkName: "component---src-pages-verify-email-verify-email-js" */),
  "component---src-pages-wallet-wallet-js": () => import("./../../../src/pages/wallet/Wallet.js" /* webpackChunkName: "component---src-pages-wallet-wallet-js" */)
}

