import PubSub from './PubSub';
import { handleNavigate } from './Navigate';
import { axios } from './axios';

const TOPICS = {
    ERROR: 'error',
    STATE_CHANGE: 'state-change'
};

class Authenticator {
    constructor() {
        this.events = new PubSub(TOPICS);
        this.isInitialized = false;
        this.user = null;
    }

    getUser() {
        return this.user;
    }

    onStateChange(data) {
        this.events._publish(this.events.topics.STATE_CHANGE, data);
    }

    async signIn(emailAddress, password) {
        if (this.isLoggedIn) {
            return;
        }

        try {
            const { data } = await axios.post('https://api-dev.webdamdesign.com/login', {
                email: emailAddress,
                password: password,
                appId: 'ProjectCharity'
            });
            this.onStateChange(data);
        } catch (err) {
            this.events._publish(this.events.topics.ERROR, { message: err.message });
            throw err;
        }
    }

    async init() {
        if (this.isInitialized) {
            return;
        }

        console.log(this.getUser());

        let isLoggedIn = false;
        try {
            // const isUserLoggedIn = await axios.get('https://api-dev.webdamdesign.com/loggedInState');

            // if (isUserLoggedIn?.session === false) {
            //     return this.onStateChange();
            // }
            const { data } = await axios.get('https://api-dev.webdamdesign.com/account');
            
            if (data) {
                isLoggedIn = true;
            }

            if (!isLoggedIn) {
                return this.onStateChange();
            }

            this.user = data;

            this.onStateChange(data);
        } catch (err) {
            this.events._publish(this.events.topics.ERROR, { message: err.message });
        }
        
        this.isInitialized = true;
    }
    
    async resetPassword() {
        
    }
    
    async resendSignUp(email) {
        try {
            await axios.post('https://api-dev.webdamdesign.com/resend-email', {
                email: email
            });
        } catch (err) {
            throw err;
        }
    }

    async signUp({ email, password, twitterHandle, instagramHandle, tiktokHandle, walletAddress, inviteReferralCode }) {
        try {
            const data = await axios.post('https://api-dev.webdamdesign.com/register', {
                email: email,
                password: password,
                twitterHandle: twitterHandle,
                instagramHandle: instagramHandle,
                tikTokHandle: tiktokHandle,
                walletAddress: walletAddress,
                inviteReferralCode: inviteReferralCode
            });
        } catch (err) {
            throw err;
        }
    }

    async forgotPassword(email) {
        try {
            await axios.post('https://api-dev.webdamdesign.com/reset-password', {
                email: email
            });
        } catch (err) {
            throw err;
        }
    }

    async forgotPasswordSubmit(code, email, password, oldPassword) {
        try {
            await axios.post('https://api-dev.webdamdesign.com/reset-password-submit', {
                email: email,
                code: code,
                password: password,
                oldPassword: oldPassword
            });
        } catch (err) {
            throw err;
        }
    }

    async confirmSignUp(email, code) {
        try {
            await axios.post('https://api-dev.webdamdesign.com/confirm-email', {
                email: email,
                code: code
            }, { 'Content-Type': 'application/octet-stream' });
        } catch (err) {
            throw err;
        }
    }

    async updateAttributes(attributes) {
        try {
            await axios.post('https://api-dev.webdamdesign.com/account', {
                ...attributes
            });
        } catch (err) {
            throw err;
        }
    }

    async changePassword(email, oldPassword, newPassword) {
        try {
            await axios.post('https://api-dev.webdamdesign.com/change-password', {
                oldPassword: oldPassword,
                password: newPassword,
                email: email
            });
        } catch (err) {
            throw err;
        }
    }

    async signOut() {
        try {
            await axios.get('https://api-dev.webdamdesign.com/logout');
            return this.onStateChange();
        } catch (err) {
            throw err;
        }
    }
}

export default new Authenticator();
